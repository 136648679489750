export const ApiEndpoints = {
  imageUpload: 'https://images-upload-ws6pzoa3da-uc.a.run.app/',
  // 'https://images-upload-hz6t7lzria-uc.a.run.app' dev endpoint
  messageUpload: 'https://us-central1-pangobooks.cloudfunctions.net/images-uploadMessageImage' // Live Endpoint
}

export function endpoints() {
  return {
    messageUpload: `${ApiEndpoints.messageUpload}`,
    imageUpload: `${ApiEndpoints.imageUpload}`
  }
}
