import COLORS from '../../../lib/helpers/color-helper';

interface Props {
  fillColor?: string;
  strokeColor?: string;
  size?: number;
}

export default function MultipleBooksIcon({
  fillColor = COLORS.primary,
  strokeColor = COLORS.copyDark,
  size = 48,
}: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.1" cx="24" cy="24" r="24" fill={fillColor} />
      <path
        d="M19 15V33M16 15H22C22.5523 15 23 15.4477 23 16V32C23 32.5523 22.5523 33 22 33H16C15.4477 33 15 32.5523 15 32V16C15 15.4477 15.4477 15 16 15ZM32.4 30.9C32.6 31.4 32.3 32 31.8 32.2L29.9 32.9C29.4 33.1 28.8 32.8 28.6 32.3L23.1 17.1C22.9 16.6 23.2 16 23.7 15.8L25.6 15.1C26.1 14.9 26.7 15.2 26.9 15.7L32.4 30.9Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
