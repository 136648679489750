'use client';

import { init as initFullStory } from '@fullstory/browser';
import { useEffect } from 'react';

export default function FullStory() {
  useEffect(() => {
    initFullStory({
      orgId: 'o-1YE2AT-na1',
    });
  }, []);
  return null;
}
