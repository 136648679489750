import { useEffect, useState } from 'react';
import {
  AnalyticsBookData,
  IngestEventProps,
  PageNames,
  ProductAdded,
  ProductAddedSource,
  ProductClick,
  ProductListView,
  ProductRemoved,
  ProductView,
  ProductViewSource,
} from 'types';
import { UserState } from '~/state/reducers/user';
import * as Sentry from '@sentry/nextjs';
import { getCookie, setCookie } from 'cookies-next';
import { createBackendFunction } from '~/clients/firebase/client';
import { v4 as uuidv4 } from 'uuid';

export default function useAnalytics() {
  const [ga4ClientId, setGa4ClientId] = useState<string>('');
  const [sessionId, setSessionId] = useState<string>('');
  const [isClient, setIsClient] = useState(false);

  const signUpGaLabel = 'c3zECLLFuN4CEJe_mMwB';
  const checkoutStartedGaLabel = 'AoZhCPP3294CEJe_mMwB';
  const productAddedGaLabel = 'X1BwCMTd294CEJe_mMwB';
  const purchaseGaLabel = 'uNxBCPzp2d4CEJe_mMwB';

  const ingestEvent = createBackendFunction<void, IngestEventProps>(
    'analytics-ingestEvent'
  );

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    // Get or create session ID
    let currentSessionId = getCookie('analytics_session_id') as string;
    if (!currentSessionId) {
      currentSessionId = uuidv4();
      setCookie('analytics_session_id', currentSessionId, {
        maxAge: 30 * 24 * 60 * 60, // 30 days
        path: '/',
      });
    }
    setSessionId(currentSessionId);

    const gtag = window.gtag;
    if (gtag) {
      gtag(
        'get',
        process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
        'client_id',
        (client_id) => {
          setGa4ClientId(client_id);
        }
      );
    }
  }, []);

  async function authEvent(
    name: 'Signed Up GA' | 'Signed In',
    method: 'apple' | 'google' | 'facebook' | 'email',
    attributes: { email: string; id: string }
  ) {
    if (name === 'Signed Up GA') {
      if (!!window?.gtag) {
        window.gtag('event', 'sign_up', {
          send_to: `AW-428220311/${signUpGaLabel}`,
        });
      }
    } else {
      await ingestEvent({
        type: 'track',
        event: name,
        properties: {
          method,
          ...attributes,
          ga4ClientId,
          sessionId,
          currentUrl: window.location.href,
          userAgent: window?.navigator?.userAgent || 'none',
        },
        source: 'web',
      });
    }
  }

  async function identifyUser({
    user,
    anonymous = false,
    ipAddress,
  }: {
    user: UserState;
    anonymous?: boolean;
    ipAddress?: string;
  }) {
    if (anonymous) {
      return ingestEvent({
        type: 'identify',
        event: 'identify',
        traits: {
          anonymous: true,
          firestoreAnonymousId: user.uid,
          userAgent: window?.navigator?.userAgent || 'none',
          ipAddress,
          sessionId,
        },
        source: 'web',
      });
    }

    try {
      Sentry.setUser({ id: user.uid });
    } catch (e: any) {
      // not throwing. Dont want to fail here
    }

    return ingestEvent({
      type: 'identify',
      event: 'identify',
      traits: {
        userToken: user.uid,
        email: user.email,
        referrer: document.referrer,
        currentUrl: window.location.href,
        username: user.username as string,
        createdAt: user.time_created?.toDate()?.toISOString(),
        name: user.name,
        books_listed: user.books_listed ?? 0,
        seller_score: user.score?.value ?? 0,
        userAgent: window?.navigator?.userAgent || 'none',
        ipAddress,
        sessionId,
      },
      source: 'web',
    });
  }

  async function productAdded(attributes: ProductAdded) {
    const value = parseFloat((attributes.product.amount / 100).toFixed(2));

    if (!!window?.gtag) {
      window.gtag('event', 'add_to_cart', {
        send_to: `AW-428220311/${productAddedGaLabel}`,
        currency: 'USD',
        value,
        items: [
          {
            item_id: attributes.product.id,
            item_name: attributes.product.title,
            quantity: 1,
          },
        ],
      });
    }

    await ingestEvent({
      type: 'track',
      event: 'Product Added',
      properties: {
        ...attributes,
        id: attributes.product.id,
        price: value,
        value,
        currency: 'USD',
        ga4ClientId,
        fbc: getCookie('_fbc') || '',
        fbp: getCookie('_fbp') || '',
        currentUrl: window.location.href,
        sessionId,
        userAgent: window?.navigator?.userAgent || 'none',
      },
      source: 'web',
    });
  }

  async function productRemoved(attributes: ProductRemoved) {
    const value = parseFloat((attributes.product.amount / 100).toFixed(2));

    await ingestEvent({
      type: 'track',
      event: 'Product Removed',
      properties: {
        ...attributes,
        id: attributes.product.id,
        price: value,
        value,
        currency: 'USD',
        ga4ClientId,
        sessionId,
        currentUrl: window.location.href,
      },
      source: 'web',
    });
  }

  async function productView(attributes: ProductView) {
    const value = parseFloat((attributes.product.amount / 100).toFixed(2));

    await ingestEvent({
      type: 'track',
      event: 'Product Viewed',
      properties: {
        ...attributes,
        id: attributes.product.id,
        price: value,
        value,
        ga4ClientId,
        fbc: getCookie('_fbc') || '',
        fbp: getCookie('_fbp') || '',
        currency: 'USD',
        currentUrl: window.location.href,
        sessionId,
        userAgent: window?.navigator?.userAgent || 'none',
      },
      source: 'web',
    });
  }

  async function cartProcess(
    name:
      | 'Cart Viewed'
      | 'Checkout Started'
      | 'Checkout Step Viewed'
      | 'Checkout Step Completed'
      | 'Optimize Cart Click',
    attributes: any
  ) {
    if (name === 'Checkout Started' && !!window?.gtag) {
      if (attributes.value && attributes.products) {
        window.gtag('event', 'begin_checkout', {
          send_to: `AW-428220311/${checkoutStartedGaLabel}`,
          currency: 'USD',
          value: attributes.value,
          items: attributes.products?.map((product: any) => ({
            item_id: product.id,
            item_name: product.title,
            quantity: 1,
          })),
        });
      }
    }

    await ingestEvent({
      type: 'track',
      event: name,
      properties: {
        ...attributes,
        ga4ClientId,
        fbc: getCookie('_fbc') || '',
        fbp: getCookie('_fbp') || '',
        currency: 'USD',
        sessionId,
        userAgent: window?.navigator?.userAgent || 'none',
      },
      source: 'web',
    });
  }

  async function checkoutProcess(
    name:
      | 'Payment Info Entered'
      | 'Order Updated'
      | 'Order Completed GA'
      | 'Order Refunded'
      | 'Order Cancelled'
      | 'Buy It Now',
    attributes: any
  ) {
    if (name === 'Order Completed GA' && !!window?.gtag) {
      window.gtag('event', 'purchase', {
        send_to: `AW-428220311/${purchaseGaLabel}`,
        transaction_id: attributes.payment_intent,
        value: attributes.value,
        currency: 'USD',
        shipping: attributes.shipping,
        items: attributes.products?.map((product: AnalyticsBookData) => ({
          item_id: product.id,
          item_name: product.title,
          price: product.price,
          quantity: 1,
        })),
      });
    }
    await ingestEvent({
      type: 'track',
      event: name,
      properties: {
        ...attributes,
        currency: 'USD',
        ga4ClientId,
        fbc: getCookie('_fbc') || '',
        fbp: getCookie('_fbp') || '',
        sessionId,
      },
      source: 'web',
    });
  }

  async function productClick(attributes: ProductClick) {
    await ingestEvent({
      type: 'track',
      event: 'Product Clicked',
      properties: {
        ...attributes,
        ga4ClientId,
        currentUrl: window.location.href,
        sessionId,
      },
      source: 'web',
    });
  }

  async function heyPangoProductClick(attributes: any) {
    await ingestEvent({
      type: 'track',
      event: 'Hey Pango Product Clicked',
      properties: {
        ...attributes,
        ga4ClientId,
        sessionId,
      },
      source: 'web',
    });
  }

  async function productListView(attributes: ProductListView) {
    return ingestEvent({
      type: 'track',
      event: 'Product List Viewed',
      properties: {
        ...attributes,
        currentUrl: window.location.href,
        sessionId,
      },
      source: 'web',
    });
  }

  function pageName(url: string): PageNames {
    switch (true) {
      case url.startsWith('/books/'):
        return 'Book';
      case url.startsWith('/bookstore'):
        return 'Bookstore';
      case url.startsWith('/search'):
        return 'Search';
      case url.startsWith('/titles'):
        return 'Title';
      case url.startsWith('/author/'):
        return 'Author';
      case url.startsWith('/author'):
        return 'Author Index';
      case url.startsWith('/account/settings/mysales'):
        return 'MySales';
      case url.startsWith('/series/'):
        return 'Series';
      case url.startsWith('/series'):
        return 'Series Index';
      case url === '/':
        return 'Home';
      default:
        return null;
    }
  }

  async function pageView(url: string) {
    const name = pageName(url);

    return ingestEvent({
      type: 'track',
      event: 'Page Viewed',
      properties: {
        pageType: name,
        url: window.location.href,
        path: window.location.pathname,
        referrer: document.referrer,
        ga4ClientId,
        fbc: getCookie('_fbc') || '',
        fbp: getCookie('_fbp') || '',
        sessionId,
      },
      source: 'web',
    });
  }

  async function addToShelf({
    source,
    type,
    shelf = '',
  }: {
    source: ProductViewSource | ProductAddedSource;
    type: 'copy' | 'title';
    title_id: string;
    shelf?: string;
  }) {
    await ingestEvent({
      type: 'track',
      event: 'Add To Shelf',
      properties: {
        source,
        type,
        shelf,
        sessionId,
      },
      source: 'web',
    });
  }

  async function shareShelf({ type }: { type: 'email' | 'link' }) {
    await ingestEvent({
      type: 'track',
      event: 'Share Shelf',
      properties: {
        type,
        sessionId,
      },
      source: 'web',
    });
  }

  async function likeShelf({ shelf }: { shelf: string }) {
    await ingestEvent({
      type: 'track',
      event: 'Like Shelf',
      properties: {
        shelf,
        sessionId,
      },
      source: 'web',
    });
  }

  async function shelfOp(params: {
    op: string;
    notify: boolean;
    is_public: boolean;
  }) {
    await ingestEvent({
      type: 'track',
      event: 'Shelf Op',
      properties: {
        ...params,
        sessionId,
      },
      source: 'web',
    });
  }

  async function viewShelf({
    shelf_id,
    username,
  }: {
    shelf_id: string;
    username: string;
  }) {
    await ingestEvent({
      type: 'track',
      event: 'View Shelf',
      properties: {
        shelf_id,
        username,
        sessionId,
      },
      source: 'web',
    });
  }

  async function searched(attributes: {
    query: string;
    titles: number;
    books: number;
    hashtags: number;
    users: number;
  }) {
    await ingestEvent({
      type: 'track',
      event: 'Searched',
      properties: {
        ...attributes,
        ga4ClientId,
        currentUrl: window.location.href,
        sessionId,
      },
      source: 'web',
    });
  }

  async function generalTrack(name: string, attributes: any) {
    await ingestEvent({
      type: 'track',
      event: name,
      properties: {
        ...attributes,
        sessionId,
        ga4ClientId,
      },
      source: 'web',
    });
  }

  return {
    analyticsLoaded: !!ga4ClientId && !!sessionId,
    gtagLoaded: isClient && !!window?.gtag,
    analyticEvents: {
      authEvent,
      cartProcess,
      checkoutProcess,
      identifyUser,
      pageView,
      productAdded,
      productClick,
      productListView,
      heyPangoProductClick,
      productRemoved,
      productView,
      searched,
      addToShelf,
      shelfOp,
      shareShelf,
      viewShelf,
      likeShelf,
      generalTrack,
    },
  };
}
