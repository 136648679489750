'use client';

import React, { createContext, useReducer, useContext, useMemo } from 'react';

import cartReducer from './reducers/cart';
import modalReducer from './reducers/modal';
import userReducer from './reducers/user';
import wishlistReducer from './reducers/wishlist';
import uiReducer from './reducers/ui';
import snackReducer from './reducers/snack';
import shelvesReducer from './reducers/shelves';

import { ActionDispatch, GlobalState, initialState } from './types';

const dispatch = (a: ActionDispatch) => {};
const Context = createContext({ state: initialState, dispatch });

export function StateProvider({ children }) {
  const [state, dispatch] = useReducer(reducers, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useGlobalState() {
  return useContext(Context);
}

const combineReducers = (reducers) => {
  return (state: GlobalState, action: ActionDispatch) => {
    return Object.keys(reducers).reduce((acc, prop) => {
      return {
        ...acc,
        ...reducers[prop]({ [prop]: acc[prop] }, action),
      };
    }, state);
  };
};

const reducers = combineReducers({
  cart: cartReducer,
  wishlist: wishlistReducer,
  modal: modalReducer,
  user: userReducer,
  ui: uiReducer,
  snack: snackReducer,
  shelves: shelvesReducer,
});
