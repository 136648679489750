import Script from 'next/script';

export default function GoogleTagManager() {
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=AW-428220311`}
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag("js", new Date());
          gtag("config", "AW-428220311", {
            page_path: window.location.pathname,
          });
        `}
      </Script>
    </>
  );
}
