import styles from '@/styles/components/card.module.scss';
import { HTMLAttributes } from 'react';
import { ChildrenLayoutProps } from 'types';
import { spacing } from '@/lib/helpers/spacing';
import { omit } from 'sdk/src/helpers/object';
import classNames from 'classnames';

export interface CardProps
  extends ChildrenLayoutProps,
    HTMLAttributes<HTMLDivElement> {
  noShadow?: boolean;
  as?: 'aside' | 'div';
  color?: 'white' | 'primary' | 'yellow' | 'tertiary';
}
export default function Card({
  m = 0,
  mb = 0,
  ml = 0,
  mr = 0,
  mt = 0,
  p = 0,
  pb = 0,
  pl = 0,
  pr = 0,
  pt = 0,
  as = 'div',
  children = null,
  noShadow = false,
  color,
  ...props
}: CardProps) {
  const dynamicStyles = {
    ...(!!m && { margin: spacing.padding * m }),
    ...(!!mb && { marginBottom: spacing.padding * mb }),
    ...(!!ml && { marginLeft: spacing.padding * ml }),
    ...(!!mr && { marginRight: spacing.padding * mr }),
    ...(!!mt && { marginTop: spacing.padding * mt }),
    ...(!!p && { padding: spacing.padding * p }),
    ...(!!pb && { paddingBottom: spacing.padding * pb }),
    ...(!!pl && { paddingLeft: spacing.padding * pl }),
    ...(!!pr && { paddingRight: spacing.padding * pr }),
    ...(!!pt && { paddingTop: spacing.padding * pt }),
  };

  const Tag = as;

  return (
    <Tag
      className={classNames(styles.container, props.className, {
        [styles.shadow]: !noShadow,
        [styles[color]]: !!color,
      })}
      style={dynamicStyles}
      {...omit(props, ['className'])}
    >
      {children}
    </Tag>
  );
}
