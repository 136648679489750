import { AlgoliaOptions, TypesenseIndex, TypesenseSearchProps } from 'types';
import { parse, stringify } from 'qs';
import urlParse from 'url-parse';

export const CONDITIONS = ['New', 'Like New', 'Excellent', 'Good', 'Fair'];

export const allDefaultFacets = 
[ 'hashtags',
  'format.display',
  'genre.display',
  'condition',
  'amount',
  'ships_from_state.name',
  'type.display',
  'discounts.type',
];

export const SORT_SELECT_OPTIONS: { name: string; value: string; index?: TypesenseIndex; }[] = [
  {
    name: 'Most Relevant',
    value: 'index=books&sort_by=_text_match:desc,score:desc',
    index: 'books',
  },
  {
    name: 'Price Low To High',
    value: 'index=books&sort_by=amount:asc,_text_match:desc,score:desc',
    index: 'books',
  },
  {
    name: 'Price High To Low',
    value: 'index=books&sort_by=amount:desc,_text_match:desc,score:desc',
    index: 'books',
  },
  {
    name: 'Most Recent',
    value: 'index=books&sort_by=timestamp:desc,_text_match:desc,score:desc',
    index: 'books',
  },
  {
    name: 'Most Wished For',
    value: 'index=books&sort_by=wishes:desc,_text_match:desc,score:desc',
    index: 'books',
  },
  {
    name: 'Oldest First',
    value: 'index=books&sort_by=timestamp:asc,_text_match:desc,score:desc',
    index: 'books',
  },
  {
    name: 'Titles Only',
    value: 'index=titles&sort_by=_text_match:desc,popularity.copies_sold:desc,copies:desc',
    index: 'titles',
  },
  {
    name: 'Most Popular Titles',
    value: 'index=titles&sort_by=popularity.score:desc,_text_match:desc,copies:desc',
    index: 'titles',
  },
  {
    name: 'Most popular',
    value: 'index=shelves&sort_by=scores.alltime_weighted_score:desc, _text_match:desc,updated:desc',
    index: 'shelves',
  },
  {
    name: 'Trending this week',
    value: 'index=shelves&sort_by=scores.last_week_weighted_score:desc, _text_match:desc,updated:desc',
    index: 'shelves'
  },
  {
    name: 'Trending this month',
    value: 'index=shelves&sort_by=scores.last_month_weighted_score:desc, _text_match:desc,updated:desc',
    index: 'shelves'
  }

];

export const facetMap: Record<string, string> = {
  books:
    'hashtags,condition,amount,genre.display,format.display,ships_from_state.name,type.display,cart_count,wishes,discounts.type',
  titles: 'hashtags,genre.display,type.display,authors.id',
};

export function parseSortBy(sortByString: string): string {
  if (!sortByString) return '';
  const parsed = parse(sortByString);
  const sort_by = parsed?.sort_by as string;
  if (!sort_by) return '';
  return sort_by;
}

export function parseIndex(indexString: string): string {
  if (!indexString) return '';
  const parsed = parse(indexString);
  const indexName = parsed?.index as string;
  if (!indexName) return '';
  return indexName;
}

export function sortSelectValue(props: TypesenseSearchProps): string {
  const { index } = props;
  const { sort_by } = props.params;
  const value = `index=${index}&sort_by=${sort_by}`;
  const option = SORT_SELECT_OPTIONS.find((option) => option.value === value);
  if (option) return option.value;
  return SORT_SELECT_OPTIONS[0].value;
}

export function parseSortSelectValue(value: string): {
  index: TypesenseIndex;
  sort_by: string;
} {
  const parsed = parse(value);
  const index = parsed.index as TypesenseIndex;
  const sort_by = parsed.sort_by as string;
  return { index, sort_by };
}

export function handleSortOrder(
  filterString = '',
  sortBy = '',
  index = 'books'
) {
  if (!sortBy) return filterString; // only need to do this if a sort is ordered on creation time
  if (index !== 'books') return filterString; // only need to do this for books
  const timeDescSorts = [
    'feed_timestamp:desc',
    'time_created:desc',
    'timestamp:desc',
  ];
  for (const sort of timeDescSorts) {
    if (sortBy.includes(sort)) {
      const milliNow = Date.now();
      const twoMinsAgo = milliNow - 1000 * 60 * 2;
      const filterName =
        sort === 'feed_timestamp:desc' ? 'feed_timestamp' : 'time_created';
      const newFilter = `${filterName}:<=${Math.round(twoMinsAgo / 1000)}`;
      return mergeFilters(filterString, newFilter);
    }
  }
  return filterString;
}

export function parseUrl(url: string | unknown) {
  let parsedUrl;
  if (typeof url === 'string' && url.length > 0) {
    const queryString = urlParse(url, true)?.query;
    if (!queryString || !queryString.search) return {} as any;

    return parse(queryString.search) as unknown as TypesenseSearchProps;
  } else {
    parsedUrl = parse(url as string);
    if (parsedUrl.search) {
      return parse(parsedUrl.search as any) as unknown as TypesenseSearchProps;
    }

    return {} as any;
  }
}

interface Filter {
  field: string;
  operator: string;
  value: string;
}

export function parseFilterString(filterString?: string) {
  const operatorRegex = /(:=|:!=|:|<=|>=|<|>)/;
  const conditions = filterString
    ? filterString
        .split('&&')
        .map((condition) => condition.trim())
        .filter((c) => c.length > 0)
    : [];

  return conditions.map((condition) => {
    const match = condition.match(operatorRegex);

    if (match === null) {
      throw new Error('Invalid operator in condition: ' + condition);
    }

    const operator = match[0];
    const parts = condition.split(operator);

    const field = parts[0].trim();

    // get full facet value to right of operator
    const value = parts.slice(1).join(':').trim();
    return {
      field,
      operator,
      value,
    };
  });
}

export function parseAppliedFilters(filterString: string) {
  const parsedString = parseFilterString(filterString);
  const appliedFiltersArray: { field: string; value: string }[] = [];
  parsedString.forEach((filter) => {
    if (allDefaultFacets.includes(filter.field as any)) {
      if (filter.field !== 'amount' && filter.field !== 'discounts.type' && filter.value[0] === '[') {
        let actualValues: any;
        try {
          actualValues = JSON.parse(filter.value.replace('..', ','));
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
        if (actualValues) {
          actualValues.forEach((value: string) => {
            appliedFiltersArray.push({ field: filter.field, value });
          });
        }
      } else {
        appliedFiltersArray.push({ field: filter.field, value: filter.value });
      }
    }
  });

  return appliedFiltersArray;
}

export function filtersArrayToString(filters: Filter[]) {
  const newString = filters
    .map((filter) => {
      return `${filter.field}${filter.operator}${filter.value}`;
    })
    .join(' && ');
  return newString;
}

export function clearFacetValue(filterString: string, facet: string | string[]) {
  // given a facet or facet array, remove all uses of it in filterString
  const filters = parseFilterString(filterString);
  let newFilters: Filter[] = [];

  if (Array.isArray(facet)) {
    newFilters = filters.filter((filter) => !facet.includes(filter.field));
  } else {
    newFilters = filters.filter((filter) => filter.field !== facet);
  }

  const newFilterString = filtersArrayToString(newFilters);
  return newFilterString;
}

export function isFacetCategorySelected(filterString: string, facet: string) {
  const filters = parseFilterString(filterString);
  return filters.some((filter) => filter.field === facet);
}

export function isFacetFilterSelected(
  filterString: string,
  facet: string,
  value: string
) {
  const filters = parseFilterString(filterString);
  const filtersForFacet = filters.filter((filter) => filter.field === facet);
  return filtersForFacet.some((filter) => {
    if (filter.value.startsWith('[') && filter.value.endsWith(']')) {
      // parse the comma separated array without using JSON.parse
      const array = filter.value
        .slice(1, -1)
        .split(',')
        .map((s) => s.trim().replace(/^"|"$/g, ''));
      return array.includes(value);
    }
    return filter.value === value;
  });
}

export function mergeFilters(
  filterString: string,
  newFilterString: string,
  action: 'AND' | 'OR' = 'AND',
): string {
  const exclusiveFacets = [
    'condition',
    'amount',
    'genre.display',
    'format.display',
  ]; // facets that can only have one value AND'd at a time. These may be OR'd
  const existingFilters = parseFilterString(filterString); // the current set of filters
  const newFilter = parseFilterString(newFilterString); // the new filter to add

  const newFilterName = newFilter[0].field; // the name of the filter
  const newFilterValue = newFilter[0].value; // the value of the filter

  const isExclusiveFacet = exclusiveFacets.includes(newFilterName);

  const matchExact = (filter: Filter) =>
    filter.field === newFilterName && filter.value === newFilterValue; // match the exact filter:value pair
  const matchFacet = (filter: Filter) => filter.field === newFilterName; // match the facet regardless of value

  const filterExact = (filter: Filter) =>
    filter.field !== newFilterName || filter.value !== newFilterValue; // remove the exact filter:value pair
  const filterFacet = (filter: Filter) => filter.field !== newFilterName; // remove the facet regardless of value

  const exactMatchFilters = existingFilters.filter(matchExact); // filters that match the exact filter:value pair
  const facetMatchFilters = existingFilters.filter(matchFacet); // filters that match the facet regardless of value
  if (action === 'OR' && facetMatchFilters?.length) {
    // OR is irrelevant if there isnt a facet match
    const newFilters = existingFilters.filter(filterFacet);
    if (facetMatchFilters?.length > 1) {
      throw new Error('Cannot add OR condition to multiple facets');
    }
    const orFilter = facetMatchFilters[0];
    // check if the value is a stringified array
    const value = orFilter.value;
    const isArray = value.startsWith('[') && value.endsWith(']');
    if (isArray) {
      // if its an array then we need to either add the value to the array or remove it from the array if it exists already
      const array = JSON.parse(value);
      const index = array.indexOf(newFilterValue);
      if (index === -1) {
        // add it to the array
        array.push(newFilterValue);
      } else {
        // remove it from the array
        array.splice(index, 1);
      }
      // if the orFilter is empty, remove it
      if (array.length === 0) {
        return filtersArrayToString(newFilters);
      }
      orFilter.value = JSON.stringify(array);
    } else {
      // if its not an array then we need to add the value to an array
      // first check for exact match
      if (value === newFilterValue) {
        // if its an exact match then we remove the filter because they are deselecting it
        return filtersArrayToString(newFilters);
      }
      orFilter.value = JSON.stringify([value, newFilterValue]);
    }
    return filtersArrayToString([...newFilters, orFilter]);
  } else if (facetMatchFilters?.length && isExclusiveFacet) {
    const newFilters = existingFilters.filter(filterFacet); // the filters with all instances of the facet removed. Regardless of value.
    if (!exactMatchFilters?.length) {
      // if there isnt an exact match then we add the new filter while removing the existing one
      return filtersArrayToString([...newFilters, ...newFilter]);
    } else {
      // if there is an exact match then we remove the filter because they are deselecting it
      // This also handles the OR case because if there is an exact match then the action is a deselect
      return filtersArrayToString(newFilters);
    }
  } else if (exactMatchFilters?.length) {
    // remove existing filter if its an exact match because they are deselecting it
    const newFilters = existingFilters.filter(filterExact);
    return filtersArrayToString(newFilters);
  } else {
    // add new filter
    // TODO handle OR condition here. If the condition is OR then look for a facet match and either add it to it or add a standalone OR.
    const newFilterString = filtersArrayToString([
      ...existingFilters,
      ...newFilter,
    ]);
    return newFilterString;
  }
}


export function removeUnsupportedFilters(
  filterString: string,
  index: TypesenseIndex,
  previousIndex?: string
): string {
  if (index === previousIndex) return filterString;
  if (index !== 'books' && index !== 'titles') return filterString;
  const facets = facetMap[index].split(',');
  const additionalFacets = ['seller_id', 'authors.id'];
  const allFacets = [...facets, ...additionalFacets];
  const filters = parseFilterString(filterString);
  const supportedFilters = filters.filter((filter) =>
    allFacets.includes(filter.field)
  );

  /**
   * Special Cases
   */
  if (index === 'titles' && previousIndex === 'books' && filters.some(f => f.field === 'title_id')) {
    // convert the title id filter to an id filter
    supportedFilters.push({
      field: 'id',
      operator: ':=',
      value: filters.find(f => f.field === 'title_id')?.value as string
    });
  }

  if (index === 'books' && previousIndex === 'titles' && filters.some(f => f.field === 'id')) {
    // convert the id filter to a title_id filter
    supportedFilters.push({
      field: 'title_id',
      operator: ':=',
      value: filters.find(f => f.field === 'id')?.value as string
    });
  }

  return filtersArrayToString(supportedFilters);
}

export function algoliaOptionsToUrlQuery(options: AlgoliaOptions) {
  const urlQuery: Partial<AlgoliaOptions> = {
    index: options.index || 'books',
    options: {
      page: options.options?.page,
      query: options.options?.query,
      facetFilters: options.options?.facetFilters,
      numericFilters: options.options?.numericFilters,
    },
  };

  return stringify(urlQuery);
}

export function formatCategoriesPercentString(str: string) {
  // Remove :{percent} from the end of the string
  const removedSuffix = str.split(':')[0];

  // Split the string into words
  const words = removedSuffix.split('_');

  // Convert all words to title case
  const titleCase = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase();
  }).join(' ');

  return titleCase;
}
